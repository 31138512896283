import React from "react";
import { string, bool } from "prop-types";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import background from "../../../images/global_assets/semi-circles-pattern.png";
import Heading from "../../Base/HeadingBuilder";

const PartnerLogosAppetize = ({
  className,
  title,
  mobileImageName,
  desktopImageName,
  large,
}) => {
  return (
    <section
      className={`
      relative
      w-full
      ${className}
    `}
      data-cy="partners"
    >
      <img
        className={`
          absolute h-full z-0
          right-0 -top-4 bottom-0
          md:top-0
        `}
        src={background}
        alt="background"
      />
      <div className="max-w-1440 w-full my-0 mx-auto px-4 lg:px-40">
        <div>
          <Heading
            level={2}
            injectionType={2}
            className={`relative
          md:max-w-70-percent md:pr-12
          xl:max-w-60-percent xl:pr-28
          z-1
        `}
            withBlueSymbol
          >
            {title}
          </Heading>
        </div>

        <AppetizeImage
          className={`
          partners-mobile
          md:hidden
          mt-4
        `}
          imageName={mobileImageName}
        />

        <div>
          <AppetizeImage
            className="hidden md:block mt-14 partners-desktop"
            imageName={desktopImageName}
          />
        </div>
      </div>
    </section>
  );
};

PartnerLogosAppetize.propTypes = {
  className: string,
  title: string.isRequired,
  mobileImageName: string.isRequired,
  desktopImageName: string.isRequired,
  large: bool,
};

PartnerLogosAppetize.defaultProps = {
  className: "",
  large: false,
};

export default PartnerLogosAppetize;
